import React from 'react';
import Helmet from 'react-helmet';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import AuthorsListing from '../../components/AuthorsListing';

const AuthorsPage = ({
  data: {
    allMarkdownRemark: { edges },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  const location = `${process.env.PAGE_DOMAIN}/authors/`;

  return (
    <Layout>
      <div className="main authors-page">
        <section className="hero">
          <div className="hero-text">
            <h1 className="page-title">Authors</h1>
          </div>
        </section>
        <section>
          <Helmet title={`Authors | ${title}`} />
          <GatsbySeo
            canonical={location}
            openGraph={{
              title: `Authors | ${title}`,
              url: `${location}`,
            }}
          />
          <div className="content">
            <AuthorsListing full={edges} AuthorNameTag="h2" />
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AuthorsPage;

export const authorsQuery = graphql`
  query AuthorsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___name], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "author" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          ...AuthorBoxFragment
        }
      }
    }
  }
`;
